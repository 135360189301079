angular.module("aerosApp")
	.factory('mergeProjectsModal', ["$uibModal", function ($uibModal) {
		function open(projects) {
			var modal = $uibModal.open({
                size: "md",
				templateUrl: "/static/templates/org/modal/merge-projects-modal.html",
				controller: "MergeProjectsCtrl",
				resolve: {
					projects: function () { return projects; }
				}
			});
			return modal.result;
		}
		return {
			open: open
		};
	}])
	.controller("MergeProjectsCtrl", [ "$scope", "$uibModalInstance", "projects", 
     function($scope, $uibModalInstance, projects) {
		$scope.projects = projects;
		$scope.formData = {};
		
		function checkClearName () {
			if ($scope.formData.mergeProjectsForm && $scope.formData.mergeProjectsForm.$pristine) {
				$scope.formData.newProjectName = "";				
			}			
		}
		function deselectProjects() {
			$scope.selectedProjects = [];
			checkClearName();
		}
		deselectProjects();

		$scope.selectProject = function (project) {
			if ( $scope.isSelected(project) ) {
				$scope.selectedProjects = _.without($scope.selectedProjects, project.id);
				checkClearName();
			} else {
				if ( $scope.selectedProjects.length > 1 ) {
					deselectProjects();
				}
				$scope.selectedProjects.push(project.id);
				if ($scope.selectedProjects.length === 2 && $scope.formData.mergeProjectsForm.$pristine) {
					var origNames = _.map(_.filter($scope.projects, function (project) {
						return _.includes($scope.selectedProjects, project.id);
					}), "name");
					$scope.formData.newProjectName = "MERGE:" + origNames.join();
					$scope.formData.mergeProjectsForm.$setPristine();
				}
			}
		};
		$scope.isSelected = function (project) {
			return _.includes($scope.selectedProjects, project.id);
		};

		$scope.cancel = function() {
			$uibModalInstance.dismiss();
		};

		$scope.mergeProjects = function() {
		    var mergeCheckBox = $("#mergeInBackground");
		    var checkBoxChecked = mergeCheckBox.prop("checked");
			var mergeObj = {
				projectIds: $scope.selectedProjects,
				newProjectName: $scope.formData.newProjectName,
                mergeFgInbackground: checkBoxChecked,
			};
			$uibModalInstance.close(mergeObj);
		};
	}]);
